<template>

  <div class="flex justify-end w-full my-5" v-if="showMe">
    <!--- validation is displayed here, userform is name of the form--->
    <div class="valiadtion-popup py-10">
      <div class="w-full flex">
        <img :src="warningIcon"  class=" warning-icon"/>
        <div class="flex flex-col">
          <p class="mb-4 font-normal">
            <span class="mb-4 font-normal text-text-blue">Email address already exists</span>
              <svg @click="closeMe" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 6L6 14" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6L14 14" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </p>
          <p class="mb-0 warningText text-xs">There is an active onboarding request with the same email address. Please use another email address for this request.</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ValidationPopupWithTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true,
    },

    showError: {
      type: Boolean
    }
  },
  data(){
    return {
      warningIcon: require("@/assets/images/icons/alert-circle.svg"),
      showMe: true
    }
  },
  mounted() {
    this.showMe = this.showError;
  },
  methods:{
    getErrorFieldId(id){
      return '#'+id
    },
    closeMe() {
      this.showMe = false;
      this.$emit("clearPopUp")
    }
  }

}
</script>