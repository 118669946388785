<template>
  <div class="introducer-page">
    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="merchantDetails" :open="true">
            <div slot="header">
              <h3 class="text-left font-normal">
                Merchant details <span class="mid-blue text-2xl mb-0">*</span>
              </h3>
            </div>
            <vs-row class="intro-admin mb-5">
              <div class="">
                <label class="w-full">Merchant name <span class="mid-blue text-lg mb-0">*</span></label>
                <vs-input v-model="formData.merchantName" v-validate="'required'" class="w-full" data-vv-as="Merchant name" name="merchantName" size="large" />
                <span v-show="errors.has('merchantName')" class="text-danger text-sm">{{ errors.first("merchantName") }}</span>
              </div>
              <div class="">
                <label class="w-full">Contact name <span class="mid-blue text-lg mb-0">*</span></label>
                <vs-input v-model="formData.contactName" v-validate="'required'" class="w-full" name="contactName" data-vv-as="Contact name" size="large" />
                <span v-show="errors.has('contactName')" class="text-danger text-sm">{{ errors.first("contactName") }}</span>
              </div>
            </vs-row>
            <vs-row class="intro-admin mb-5">
              <div>
                <label class="w-full">Email address <span class="mid-blue text-lg mb-0">*</span></label>
                <vs-input v-model="formData.email" v-validate="'required|email'" class="w-full" name="email" size="large" ref="email" :class="{ 'has-error': errors.has('email') }" data-vv-name="email"/>
                <span v-show="errors.has('email')" class="text-danger text-sm">{{ errors.first("email") }}</span>
              </div>
              <div>
                <label class="w-full">Confirm email address <span class="mid-blue text-lg mb-0">*</span></label>
                <vs-input v-model="formData.confirmEmail" class="w-full" name="confirmEmail" id="confirmEmail" size="large" v-validate="'confirmed:email'" />
                <span v-show="errors.has('confirmEmail')" class="text-danger text-sm">{{ errors.first("confirmEmail") }}</span>
              </div>
            </vs-row>
            <vs-row class="intro-admin mb-5">
              <div>
                <label class="w-full">Pre-assigned credit limit</label>
                <money
                  id="creditLimit"
                  v-bind="creditLimitBind"
                  v-model.lazy="formData.creditLimit"
                  name="creditLimit"
                  data-vv-as="credit limit"
                  class="vs-inputx vs-input--input large"
                  @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                ></money>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!------Onboarding profile------>
    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="profileDetails" :open="true">
            <div slot="header">
              <h3 class="text-left font-normal">Onboarding profile</h3>
            </div>
            <vs-row class="intro-admin mb-5">
              <div class="">
                <label class="w-full">Profile</label>
                <vs-select v-model="formData.onboardingProfileId" id="onboardingProfile" name="onboardingProfileId" class="w-12/12" data-vv-as="Settlement Account" data-vv-validate-on="blur">
                  <vs-select-item :key="index" :value="item._id" :text="item.name" v-for="(item, index) in onboardingProfiles" />
                </vs-select>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- Payment Options -->
    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="paymentOptions" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Payment options <span class="mid-blue text-2xl mb-0">*</span>
              </h3>
            </div>
            <p>Select the payment options you'd like to enable for this merchant.</p>
            <vs-row class="mt-8">
              <vs-col class="pr-6" vs-lg="3" vs-sm="6" vs-xs="6">
                <vs-checkbox @change="handlePayNow()" v-model="paynow">{{ appName }} payments</vs-checkbox>
              </vs-col>
              <vs-col class="pr-6" vs-lg="3" vs-sm="6" vs-xs="6">
                <vs-checkbox v-model="paylater">{{ appName }} finance products</vs-checkbox>
              </vs-col>
            </vs-row>
            <div v-if="showPaymentOptionError" class="mt-4">
              <span class="text-danger text-sm">Select at least one payment option</span>
            </div>

            <div class="flex mt-4 justify-between" v-if="paynow">
              <div class="mr-6 segmented-btn-product">
                <vs-checkbox @change="selectDefaultCards()" v-model="payNowOptions" vs-value="CARDS" v-validate="'required'" name="payNowOptions" data-vv-as="payment option">Card payments</vs-checkbox>
                <vs-checkbox v-model="payNowOptions" class="mt-2" vs-value="EFT" v-validate="'required'" name="payNowOptions" data-vv-as="payment option">EFT payments</vs-checkbox>

                <span class="text-danger text-sm" v-show="errors.has('payNowOptions')">{{ errors.first("payNowOptions") }}</span>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- PayNow payment methods -->
    <vs-row v-if="paynow && payNowOptions.includes('CARDS')">
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="payNowPaymentMethods" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Card payment settings
                <span class="mid-blue text-2xl mb-0">*</span>
              </h3>
            </div>
            <span>Set the default cards & surcharges to apply to this onboarding request</span>
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select multiple autocomplete class="selectExample" v-model="selectedCards" @change="checkSelected" placeholder="Select payment methods" v-validate="'required'" name="card">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllCards">{{ !allCardsSelected ? "Select all" : "Unselect all" }}</a>
                    </div>
                    <vs-select-item :key="index" :value="card" :text="card.title" v-for="(card, index) in cardsList" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('card')">{{ errors.first("card") }}</span>
                </vs-row>
              </vs-col>
            </vs-row>
            <!-- groups card -->

            <vs-row class="intro-cards-container">
              <vs-card class="intro-cards" v-for="(card, indextr) in selectedCards" :key="indextr">
                <div slot="header">
                  <vs-row class="flex iitems-center justify-between">
                    <div class="flex items-center">
                      <S3ImageGenerator :is-base64="false" :custom-class="'card-image'" :document="card.icon" :key="card.icon"/>
                      <h6 class="font-normal text-xl ml-4">{{ card.title }}</h6>
                    </div>
                    <div class="element">
                      <a @click="closeCard(indextr, card._id)"><x-icon size="1.5x" class="custom-class"></x-icon></a>
                    </div>
                  </vs-row>
                </div>
                <div>
                  <vs-row>
                    <span class="text-base font-light">MSF <span class="mid-blue text-lg mb-0">*</span></span>
                  </vs-row>
                  <vs-row>
                    <vs-select placeholder="Select MSF rate" class="selectExample" v-model="card.msf" @change="checkMsf(indextr)">
                      <vs-select-item :key="index" :value="msfRate.msf" :text="msfRate.displayMsf" v-for="(msfRate, index) in getMsfRates(card.title)" />
                    </vs-select>
                    <span v-show="errors.has('cardMsf[' + indextr + ']')" class="text-danger text-sm">{{ errors.first("cardMsf[" + indextr + "]") }}</span>
                  </vs-row>
                </div>
                <div class="mt-5">
                  <vs-row>
                    <span class="text-base font-light">Card surcharge <span class="mid-blue text-lg mb-0">*</span></span>
                  </vs-row>
                  <vs-row>
                    <money
                      id="surcharge"
                      v-bind="money2"
                      v-model.lazy="card.surcharge"
                      :name="`surcharge[${indextr}]`"
                      data-vv-as="surcharge"
                      v-validate="`required|decimal|max_value:${card.msf}|min_value:0.00`"
                      class="vs-inputx vs-input--input large"
                      @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                    ></money>
                    <span v-show="errors.has('surcharge[' + indextr + ']')" class="text-danger text-sm">{{ errors.first("surcharge[" + indextr + "]") }}</span>
                  </vs-row>
                </div>
              </vs-card>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- payLater products starts here -->

    <vs-row v-if="paylater" class="mt-5">
      <vx-card class="accordin-card">
        <vs-collapse accordion>
          <vs-collapse-item ref="paylaterProducts" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Finance products <span class="mid-blue text-2xl mb-0">*</span>
              </h3>
            </div>
            <p class="mb-4">
              Select the Pay Later products you'd like to offer this merchant.
            </p>
            <!-- dropdown -->
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select placeholder="Select products" multiple autocomplete class="selectExample" v-model="selectedProducts" @change="checkSelectedProducts" v-validate="'required'" name="product">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllProducts">{{ !allProductsSelected ? "Select all" : "Unselect all" }}</a>
                    </div>
                    <vs-select-item :key="index" :value="paylaterProduct" :text="paylaterProduct.productName" v-for="(paylaterProduct, index) in paylaterProductsList" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('product')">{{ errors.first("product") }}</span>
                </vs-row>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col>
                <div class="plans-grid">
                  <div v-for="(plan, index) in selectedProducts" :key="index" class="plans-item">
                    <div class="plan-items__header w-full flex justify-between">
                      <div>
                        <h4 class="mb-0 font-normal text-xl">{{ plan.productName }}</h4>
                        <p class="py-2">
                          {{ plan.feeModel == "MSF_INCLUDED" ? "MSF to merchant (MSF-M)" : "MSF to customer (MSF-C)" }}
                        </p>
                        <p>
                          Plan ID:&nbsp; {{ plan.customPlanId ? plan.customPlanId : plan.planId }}
                        </p>
                      </div>
                      <x-icon class="plan-item__close cursor-pointer" size="1.5x" @click="closeProduct(index)"></x-icon>
                    </div>
                    <div class="plan-items-content onb">
                      <div class="plan-items-info">
                        <label class="w-full font-light text-base" for="msfEdit">Service fee <span class="mid-blue text-lg mb-0">*</span></label>
                        <money
                          id="msfEdit"
                          v-bind="money2"
                          v-model.lazy="plan.msf"
                          :name="`planMsf[${index}]`"
                          data-vv-as="msf"
                          v-validate="`required|decimal|max_value:100|min_value:0.00`"
                          class="vs-inputx vs-input--input large mb-10"
                          @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                        ></money>
                        <span v-show="errors.has('planMsf[' + index + ']')" class="text-danger text-sm">{{ errors.first("planMsf[" + index + "]") }}</span>
                      </div>
                      <vs-button v-round class="w-full only-border-btn" @click="openPlanConfigurationPopup(plan, index)">View plan configuration</vs-button>
                    </div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- popup starts-->
    <vs-popup id="holamundo" class="config-popup paymentSummaryPopup" :title="`${activePlan.productName} configuration`" :active.sync="popupActive" @close="closePopup">
      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPL'">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start gray">
            <div class="flex flex-col justify-center items-center">
              <img :src="update" alt="update" />
              <span class="dark-blue text-xs mt-2"> Every</span>
              <span class="dark-blue text-xs">{{ `${this.everyNumber} ${this.weekOrMonthOrDay}` }}</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="playCircle" alt="playcircle" /><span class="dark-blue text-xs mt-2">Starts on</span>
              <span class="dark-blue text-xs">acceptance date</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="stopCircle" alt="stopcircle" />
              <span class="dark-blue text-xs mt-2"> Ends after</span>
              <span class="dark-blue text-xs">{{ `${this.activePlan.noOfPayments} payments` }}</span>
            </div>
          </vs-row>
          <vs-table :data="paymentSchedules" class="payment-schedule-table mt-3" >
            <template slot="thead">
              <vs-th class="text-base"><span class="font-medium text-grey">Payment date</span></vs-th>
              <vs-th class="text-base font-medium"><span class="font-medium text-grey">Amount</span></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].value">
                  {{ formatScheduleDate(data[indextr].value) == formatScheduleDate(new Date()) ? "Today" : formatScheduleDate(data[indextr].value) }}
                </vs-td>
                <vs-td class="sec-row-payment" :data="data[indextr].label">
                  {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- <hr class="mt-2 mb-5" /> -->
          <div class="vs-table--content">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-"}}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>

      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPO' || activePlan.productType == 'B2C_BNPO_NC'">
        <vs-col vs-w="12">
          <div class="gray mb-5 items-center flex">
            <div><img :src="calenderImage" alt="calenderImage" /></div>
            <p class="dark-blue text-base font-normal ml-4">{{ productText }}</p>
          </div>
          <div class="b2c">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>
    </vs-popup>
    <!-- popup ends -->

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>
    <pop-up-with-title v-if="showPopUpErrorWithTitle" :title="'Test'" :body="'Body'" :showError="showPopUpErrorWithTitle" @clearPopUp="clearPopUp" />

    <!-- paylater product ends here -->
    <div class="flex flex-wrap mt-10 items-center">
      <vs-button v-round class="ml-auto mr-5" size="large" @click="submitOnboardingRequest">Send</vs-button>
      <u> <a size="large" @click="$router.go(-1)">Cancel</a></u>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { Validator } from "vee-validate";
import { XIcon } from "vue-feather-icons";

import PopUp from "@/views/components/ValidationPopup.vue";
import PopUpWithTitle from "@/views/components/ValidationPopupWithTitle.vue";
import moment from "moment";
import { TheMask } from "vue-the-mask";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");
import S3ImageGenerator from "../../components/S3ImageGenerator";
import { Money } from "v-money";

const dictionary = {
  en: {
    attributes: {
      confirmEmail: "Email addresses",
      cardMsf: "msf",
    },
  },
};
Validator.localize(dictionary);

export default {
  components: { XIcon, PopUp, PopUpWithTitle, TheMask, S3ImageGenerator, Money },
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      everyNumber: "1",
      formData: {
        merchantName: "",
        contactName: "",
        email: "",
        confirmEmail: "",
        requestType: [],
        requestOptions: [],
        payLaterPlan: "",
        cardOptions: "",
        onboardingProfileId: "",
        creditLimit: ""
      },
      paynow: false,
      paylater: false,
      cardsList: [],
      selectedCards: [],
      allCardsSelected: false,
      paylaterProductsList: [],
      selectedProducts: [],
      allProductsSelected: false,
      amount: "1000",
      popupActive: false,
      weekOrMonthOrDay: "",
      activePlan: {},
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      paymentSchedules: [],
      totalCustomerPayable: "",
      merchantAmount: "",
      showPopUpErrorWithTitle: false,
      onboardingProfiles: [],
      productText: "",
      money2: {
        precision: 2,
        suffix: " %"
      },
      creditLimitBind: {
        precision: 2,
        prefix: "$ "
      },
      msfRates: [],
      payNowOptions: ["CARDS", "EFT"],
      showPaymentOptionError: false
    };
  },

  methods: {
    ...mapActions("card", ["fetchAllCards", "deletePartnerCard"]),
    ...mapActions("paymentPlans", ["fetchAllPlans"]),
    ...mapActions("onboardingAdmin", ["calculateOnPayLater", "sendOnboardingRequest"]),
    ...mapActions("onboardingProfile", ["fetchOnboardingProfileForList"]),
    ...mapActions("setting", ["fetchSetting"]),

    getMsfRates(card) {
      let rates = [...this.msfRates];

      if (["mastercard", "visa"].includes(card.toLowerCase())) {
        rates = rates.filter(item => item.msf <= this.maxCardMsf);
      }

      return rates;
    },

    //* functions for plan configuration popup start here
    async openPlanConfigurationPopup(plan, index) {
      this.activePlan = plan;
      this.popupActive = true;

      await this.calculateOnPayLater({ planId: plan._id, msf: this.selectedProducts[index].msf }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        } else {
          this.productText = plan.productText;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

      //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY":
            this.weekOrMonthOrDay = "day";
            break;

          case "FORTNIGHTLY":
            this.everyNumber = "2";
            this.weekOrMonthOrDay = "weeks";
            break;

          case "MONTHLY":
            this.weekOrMonthOrDay = "month";
            break;

          case "WEEKLY":
            this.weekOrMonthOrDay = "week";
            break;
        }
      }
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },
    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    closePopup() {
      this.weekOrMonthOrDay = "";
      this.activePlan = {};
      this.paymentSchedules = [];
      this.totalCustomerPayable = "";
      this.merchantAmount = "";
    },

    // functions for plan configuration popup ends here

    // for payNow
    async getAllCards() {
      await this.fetchAllCards().then((res) => {
        this.cardsList = res.data.data;
        this.cardsList.map(card => {
          card.surcharge = this.addZeroes(card.surcharge)
          card.msf = this.addZeroes(card.msf)
        });
      });
    },

    closeCard(index, id) {
      if (index > -1) {
        this.selectedCards.splice(index, 1);
        const cardIndex = this.cardsList.findIndex(item => item._id == id);

        if (cardIndex > -1) {
          this.cardsList[cardIndex].surcharge = this.cardsList[cardIndex].msf;
        }
      }
    },

    selectAllCards() {
      this.allCardsSelected = !this.allCardsSelected;
      if (this.allCardsSelected) {
        this.selectedCards = [...this.cardsList];
      } else {
        this.selectedCards = [];
      }
    },
    checkSelected() {
      if (this.selectedCards.length == this.cardsList.length) {
        this.allCardsSelected = true;
      } else {
        this.allCardsSelected = false;
      }
    },

    // for paylater
    async getAllPayLaterProducts() {
      await this.fetchAllPlans("?page=1&limit=1000&sort=desc").then((res) => {
        this.paylaterProductsList = res.data.data.docs;
      });
    },

    closeProduct(index) {
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      }
    },

    selectAllProducts() {
      this.allProductsSelected = !this.allProductsSelected;

      if (this.allProductsSelected) {
        this.selectedProducts = [...this.paylaterProductsList];
      } else {
        this.selectedProducts = [];
      }
    },

    checkSelectedProducts() {
      if (this.selectedProducts.length == this.paylaterProductsList.length) {
        this.allProductsSelected = true;
      } else {
        this.allProductsSelected = false;
      }
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    // click save button
    async submitOnboardingRequest() {
      try {
        if (this.errors.any()) {
          return false;
        }

        const valid = await this.$validator.validate();

        if (!this.paynow && !this.paylater) {
          this.showPaymentOptionError = true;
        }

        if (!valid || (!this.paynow && !this.paylater)) {
          return false;
        }

        this.$vs.loading();

        if (this.paynow == true) {
          this.formData.requestType = ["one-off", "recurring"];
          this.formData.requestOptions = ["pay-now"];
        }

        if (this.paylater == true) {
          this.formData.requestOptions = ["pay-later"];
        }

        if (this.paylater == true && this.paynow == true) {
          this.formData.requestOptions = ["pay-now", "pay-later"];
        }

        if (this.paylater == true && this.paynow == false) {
          this.formData.requestType = ["one-off"];
        }

        if (this.selectedProducts.length > 0) {
          this.formData.payLaterPlan = this.selectedProducts.map((el) => {
            return {
              customPlanId: el.customPlanId,
              msf: el.msf,
            };
          });
        }

        if (this.selectedCards.length > 0) {
          this.formData.cardOptions = this.selectedCards;
        }

        this.formData.eftType = this.payNowOptions.includes("EFT") ? "platform" : "merchant";
        this.formData.payNowOptions = this.paynow ? this.payNowOptions : [];

        // CREATE NEW onboarding request
        this.sendOnboardingRequest(this.formData).then((res) => {
          this.$vs.loading.close();
          this.showMessage("Success", "Onboarding request has been sent successfully.", "success", "icon-check-circle");
          this.$router.push({ name: "onboarding-admin" });
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
          this.showPopUpErrorWithTitle = true;
        });
      } catch (ex) {
        this.showPopUpErrorWithTitle = true;
        this.$vs.loading.close();
      }
    },

    clearPopUp() {
      this.showPopUpErrorWithTitle = false;
    },

    async getOnboardingProfile() {
      this.fetchOnboardingProfileForList().then((response) => {
        this.onboardingProfiles = response.data.data;
        this.onboardingProfiles.push({ _id:"", name:"None" });
      }).catch((ex) => {});
    },

    handlePayNow() {
      this.payNowOptions = this.paynow ? ["CARDS", "EFT"] : [];
      this.selectDefaultCards();
    },

    selectDefaultCards() {
      if (this.payNowOptions.includes("CARDS")) {
        const selectedCard = this.cardsList.filter((item) => {
          item.surcharge = item.msf;
          return item.slug == "visa" || item.slug == "mastercard";
        });

        this.selectedCards = selectedCard;
      } else {
        this.selectedCards = [];
      }
    },

    checkSurcharge(card, index){
      let surcharge = card.surcharge.split("%")
      let msf = card.msf.split("%");

      if (Number(surcharge[0]) > Number(msf[0])) {
        this.errors.add({ field: `surcharge[${index}]`, msg: "Surcharge can not be greater than MSF." });
      }
    },

    async getMsfSetting() {
      await this.fetchSetting('surcharge').then((res) => {
        this.msfRates = res.data.data.map((element) => {
          return {
            ...element,
            displayMsf: element.msf + " %"
          };
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching setting",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },

    checkMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);

      if (objIndex < 0) {
        this.errors.add({
          "field" : `cardMsf[${index}]`,
          "msg": "Please select valid msf from the list"
        });
      } else {
        if (this.errors.has(`cardMsf[${index}]`)) {
          this.errors.remove(`cardMsf[${index}]`);
        }
      }

      if (this.selectedCards[index].surcharge > this.selectedCards[index].msf) {
        this.errors.add({
          "field": `surcharge[${index}]`,
          "msg": `The surcharge field must be ${this.selectedCards[index].msf} or less`
        });
      } else {
        if (this.errors.has(`surcharge[${index}]`)) {
          this.errors.remove(`surcharge[${index}]`);
        }
      }
    },

    assignDefaultMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);

      if (objIndex < 0) {
        this.selectedCards[index].msf = "1.50";
        this.selectedCards[index].surcharge = "1.50";
      }
    }
  },

  watch: {
    "errors.items"(val) {
      if (this.errors.items.length > 0) {
        this.$refs.merchantDetails.maxHeight = "none !important";
      }
    },

    selectedCards(val) {
      if (this.$refs.payNowPaymentMethods) {
        this.$refs.payNowPaymentMethods.maxHeight = "none !important";
      }

      this.assignDefaultMsf(this.selectedCards.length - 1);
    },

    selectedProducts(val) {
      if (this.$refs.paylaterProducts) {
        this.$refs.paylaterProducts.maxHeight = "none !important";
      }
    },

    paynow(val) {
      if (val || this.paylater) {
        this.showPaymentOptionError = false;
      } else {
        this.showPaymentOptionError = true;
      }
    },

    paylater(val) {
      if (val || this.paynow) {
        this.showPaymentOptionError = false;
      } else {
        this.showPaymentOptionError = true;
      }
    },
  },

  async created() {
    this.getMsfSetting();
    this.getAllCards();
    this.getAllPayLaterProducts();
    this.getOnboardingProfile();
  },
};
</script>

